<template>
  <div class="dns-table">
    <base-table
      :columns="tableHead"
      :list="paginList"
      :sort="sort"
      class="dns-table__table"
      @sort-change="onSortChange"
    >
      <dns-service-table-row
        v-for="item in paginList"
        :key="item.id"
        :columns="tableHead"
        :item="item"
        @click-more="$emit('click-more', item)"
      />
    </base-table>
    <NewPagination
      :step="pagin.step"
      :start="pagin.start || 0"
      :count="list.length"
      type="base"
      @set-step="setStep"
      @new-pagination="changePagination"
    />
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable.vue';
import DnsServiceTableRow from './DnsServiceTableRow.vue';
import NewPagination from '@/components/Pagination/NewPagination';
import pagination from '@/mixins/pagination';
import tableSorting from '@/mixins/tableSorting';
export default {
  name: 'DnsServiceTable',
  components: { BaseTable, DnsServiceTableRow, NewPagination },
  mixins: [pagination, tableSorting],
  props: {
    dataset: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableHead: [
        {
          key: 'title',
          label: this.$t('tableHead.title'),
          style: {
            width: '230px',
            maxWidth: '230px',
          },
          sort: {
            prop: 'title',
            order: 'asc',
          },
        },
        {
          key: 'id',
          label: this.$t('tableHead.id'),
          style: {
            width: '80px',
          },
          sort: {
            prop: 'id',
            order: 'asc',
          },
        },
        {
          key: 'expiredate',
          label: this.$t('tableHead.expiredate'),
          style: {
            width: '44px',
          },
          sort: {
            prop: 'expiredate',
            order: 'asc',
          },
        },
        {
          key: 'autoprolong',
          label: this.$t('tableHead.autoprolong'),
          style: {
            width: '44px',
          },
        },
        {
          key: 'cost',
          label: this.$t('tableHead.cost'),
          style: {
            width: '90px',
          },
          sort: {
            prop: 'cost',
            order: 'asc',
          },
        },
        // {
        //   key: 'ns',
        //   label: this.$t('tableHead.ns'),
        //   style: {
        //     width: '160px',
        //   },
        // },
        {
          key: 'status',
          label: this.$t('tableHead.status'),
          style: {
            width: '106px',
          },
          sort: {
            prop: 'status',
            order: 'asc',
          },
        },
        {
          key: 'more',
          style: {
            width: '44px',
          },
        },
      ],
      moduleMain: 'moduleDomains.moduleDomainsDnsHost',
    };
  },
  computed: {
    list() {
      return this.$store.state.moduleDomains.moduleDomainsDnsHost.dnsList;
      // return this.$store.getters['moduleDomains/moduleDomainsDnsHost/GET_SORTED_LIST'];
    },
    tools() {
      return this.$store.state.moduleDomains.moduleDomainsDnsHost.tools;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "tableHead": {
      "title": "Тариф",
      "id": "id",
      "expiredate": "Действует до",
      "autoprolong": "Автопродление",
      "active": "Активен",
      "cost": "Цена",
      "status": "Статус"
    },
    "activeUnder": "До {date}"
  }
}
</i18n>
<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.dns-table {
  width: 100%;

  &__tools {
    flexy(space-between, center);
    margin-bottom: 1.5rem;

    &-end {
      flexy(flex-end, center);
    }

    &-note {
      margin-right: 2.5rem;
    }
  }

  &__pagination {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
