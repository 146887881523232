/**
 * @prop {String} moduleMain - current store module
 */
export default {
  computed: {
    moduleState() {
      const splitted = this.moduleMain.split('.');
      return splitted.reduce((acc, item) => {
        return acc[item];
      }, this.$store.state);
    },
    modulePath() {
      return this.moduleMain.replaceAll('.', '/');
    },
    sort() {
      return this.moduleState.sort;
    },
  },
  watch: {
    sort: {
      handler(val) {
        this.updateSort(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.updateSort(this.sort);
  },
  methods: {
    onSortChange(payload) {
      let { prop, order } = payload;
      if (this.sort.prop === prop) order = order === 'asc' ? 'desc' : 'asc';
      this.$store.commit(`${this.modulePath}/SET_SORT`, { prop, order });
    },
    updateSort(sort) {
      const col = this.tableHead.find(i => i.sort && i.sort.prop === sort.prop);
      col.sort.order = sort.order;
    },
  },
};
