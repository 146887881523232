<template>
  <div class="base-prolong-single">
    <base-prolong
      :title="tariff.pricelist"
      :list="innerList"
      @ready="onReady"
      @change="onChange"
      @change-total="onChangeTotal"
    />
  </div>
</template>

<script>
import BaseProlong from '@/components/BaseProlong/BaseProlong';
import { isNumber } from 'lodash';
import BaseConfigurator from '@/models/base/BaseConfigurator';
export default {
  name: 'BaseProlongSingle',
  components: { BaseProlong },
  props: {
    tariff: {
      type: BaseConfigurator,
      required: true,
    },
    list: {
      type: Array,
      default: () => [],
      validator: arr => !arr.length || arr.every(i => i.k && isNumber(i.cost) && i.label),
    },
    value: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    innerList() {
      return [
        {
          title: this.title ? this.title : this.$t('title'),
          list: this.list,
          value: this.value,
          id: this.tariff.id,
        },
      ];
    },
  },
  methods: {
    onReady() {
      this.$emit('ready');
    },
    onChange(value) {
      this.$emit('change', value[this.tariff.id]);
    },
    onChangeTotal(value) {
      this.$emit('change-total', value);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Продление тарифа"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.base-prolong-single {}
</style>
